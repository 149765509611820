import React, { useEffect, useRef } from "react";
import { PageLayout } from "@components";
import { APP_URL } from "../../components/EnvironmentHelper/EnvironmentHelper";

const REDIRECT_LINK_ID = "SIGNUP_LINK";

const INTERVAL = 100;
const TIMEOUT = 2000;

export default function ReferralRedirect({ location }: any) {
	const referralCode = location.search.slice(1);
	const redirectUrl = `${APP_URL}/signup/?referralCode=${referralCode}`;
	const gtmCheckCount = useRef(0);

	// Simulate click on link so that the gtm event will fire
	useEffect(() => {
		// Fix bug in prod where the href of the link is wrong
		const link = document.querySelector(`#${REDIRECT_LINK_ID}`) as any;
		link.href = redirectUrl;

		const interval = setInterval(() => {
			const gtmLoad =
				(window as any)?.google_tag_manager?.dataLayer?.gtmLoad ||
				false;
			const timeout = gtmCheckCount.current > TIMEOUT / INTERVAL;
			// Has gtm loaded?
			if (gtmLoad || timeout) {
				clearInterval(interval);
				link?.click();
			}
			gtmCheckCount.current = gtmCheckCount.current + 1;
		}, INTERVAL);

		return () => clearInterval(interval);
	}, []);

	return (
		<div style={{ display: "none" }}>
			<PageLayout
				defaultHeader="light"
				seoOptions={{ type: "PREDEFINED", pageName: "why-genemod" }}
			>
				<a href={redirectUrl} id={REDIRECT_LINK_ID}>
					{redirectUrl}
				</a>
			</PageLayout>
		</div>
	);
}
